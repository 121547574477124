import React, { ReactNode } from "react"
import {
  Map,
  MapProps,
  Layout,
  Container,
  Icon,
  HtmlContent,
  Tel,
  Section,
} from "../../components"
import { Template } from "../template.types"

export const ContactPageTemplate = ({
  mapCenter,
  contact,
  layout,
}: ContactPageTemplateProps) => {
  return (
    <Layout {...layout}>
      <Section className="h-top-content-height" noPaddingTop>
        <Container className="h-3/5 md:h-3/4">
          <Map className="h-full" center={mapCenter} />
        </Container>
        <Container className="h-2/5 flex-1 overflow-visible pt-5 sm:pt-8 md:h-1/4">
          <h1 className="text-lg font-bold">{contact.title}</h1>
          <HtmlContent className="mt-2 flex flex-col space-y-2">
            <address className="flex items-center space-x-4 not-italic">
              <Icon name="home" className="h-auto w-6" title="Adresse" />
              <span>{contact.addressString}</span>
            </address>
            <span className="flex items-center space-x-4">
              <Icon name="phone" className="h-auto w-6" title="Telefon" />
              <span>
                T <Tel phone={contact.phone} />
              </span>
            </span>
            <span className="flex items-center space-x-4">
              <Icon name="mail" className="h-auto w-6" title="E-Mail" />
              <a href={`mailto:${contact.mail}`}>{contact.mail}</a>
            </span>
          </HtmlContent>
        </Container>
      </Section>
    </Layout>
  )
}

export interface ContactPageTemplateProps extends Template {
  mapCenter: MapProps["center"]
  contact: {
    title: ReactNode
    addressString: string
    phone: string
    mail: string
  }
}
