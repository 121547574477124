import React, { ReactElement, Fragment } from "react"
import { GridList, Layout, Section, Container, FadeIn } from "../../components"
import { Template } from "../template.types"

export const GridListTemplate = ({ layout, items }: GridListTemplateProps) => {
  return (
    <Layout {...layout}>
      <FadeIn>
        <Section as={Container} noPaddingTop>
          <GridList>
            {items.map((item, key) => (
              <Fragment key={key}>{item}</Fragment>
            ))}
          </GridList>
        </Section>
      </FadeIn>
    </Layout>
  )
}

export interface GridListTemplateProps extends Template {
  // Items are supposed to have a key prop
  items: ReactElement[]
}
