import React from "react"
import { Button } from "../../components"
import { ReactElement, cloneElement } from "react"

/** Returns the link with a button wrapped around, the link's children will be the button's children */
export const cloneLinkWithBtn = (link: ReactElement) =>
  cloneElement(
    link,
    {},
    <Button className="px-2 text-xs sm:px-6 sm:text-base">
      {link.props.children}
    </Button>
  )
