import React, { ReactElement } from "react"
import {
  ProjectBlocks,
  type ProjectBlocksProps,
  Layout,
  Section,
  Container,
} from "../../components"
import { type Template } from "../template.types"
import { cloneLinkWithBtn } from "./cloneLinkWithBtn"

export const SingleProjectTemplate = ({
  layout,
  blocks,
  projectPageLink,
  nextProjectLink,
}: SingleProjectTemplateProps) => {
  // clone links with button wrapped around
  const projectPageBtn = cloneLinkWithBtn(projectPageLink)
  const nextProjectBtn = cloneLinkWithBtn(nextProjectLink)
  return (
    <Layout {...layout}>
      <ProjectBlocks noPaddingTop noPaddingBottom {...{ blocks }} />
      {/* Next and prev buttons */}
      <Section as={Container} className="flex justify-between">
        {projectPageBtn}
        {nextProjectBtn}
      </Section>
    </Layout>
  )
}

export interface SingleProjectTemplateProps extends Template {
  blocks: ProjectBlocksProps["blocks"]
  projectPageLink: ReactElement
  nextProjectLink: ReactElement
}
