import React, { ReactNode, ReactElement, cloneElement, useRef } from "react"
import clsx from "clsx"
import { Section, Container, HtmlContent, Button } from "../../components"
import { useInViewport } from "react-in-viewport"

export const Teaser = ({ content, link, dark = false }: TeaserProps) => {
  // Wrap link around a button
  const ref = useRef<HTMLDivElement>(null)
  const { inViewport, enterCount } = useInViewport(ref, { rootMargin: "-15%" })
  const clonedLinkWithButton = cloneElement(
    link,
    { className: "mt-6 inline-block" },
    <Button>{link.props.children}</Button>
  )

  const styles = clsx(
    "themed",
    dark && "dark",
    (inViewport || enterCount >= 1) && "in-viewport"
  )
  return (
    <div ref={ref}>
      <Section className={styles}>
        <Container>
          <HtmlContent className="lg:prose-xl">
            {content}
            {clonedLinkWithButton}
          </HtmlContent>
        </Container>
      </Section>
    </div>
  )
}

export interface TeaserProps {
  content: ReactNode
  link: ReactElement
  dark?: boolean
}
