import React from "react"
import {
  FadeCarousel,
  FadeCarouselProps,
  Container,
  Layout,
  Section,
  FadeIn,
} from "../../components"
import { Teaser, TeaserProps } from "./Teaser"
import { Template } from "../template.types.d"

export const FrontPageTemplate = ({
  fadeCarousel,
  teamTeaser,
  jobofferTeaser,
  contactTeaser,
  layout,
}: FrontPageTemplateProps) => {
  return (
    <Layout {...layout}>
      <Section
        as={Container}
        className="h-[66.6vh] sm:h-top-content-height"
        noPaddingTop
      >
        <FadeIn className="h-full">
          <FadeCarousel className="h-full" {...fadeCarousel} />
        </FadeIn>
      </Section>
      <Teaser {...jobofferTeaser} />
      <Teaser {...teamTeaser} dark />
      <Teaser {...contactTeaser} />
    </Layout>
  )
}

export interface FrontPageTemplateProps extends Template {
  fadeCarousel: FadeCarouselProps
  jobofferTeaser: TeaserProps
  teamTeaser: TeaserProps
  contactTeaser: TeaserProps
}
