import React from "react"
import { Seo } from "../gatsby-components"
import { ContactPageTemplate, ContactPageTemplateProps } from "../templates"
import { layout } from "../temp-content/layout"

export const ContactPage = () => {
  const contact: ContactPageTemplateProps["contact"] = {
    title: "BOCHEM.SCHMIDT Architekten PartGmbB",
    addressString: "Schwarzenbergstr. 12 – 14, 66663 Merzig",
    phone: "+49 6861 . 91525 0",
    mail: "mail@bochemschmidt.de",
  }
  const mapCenter: ContactPageTemplateProps["mapCenter"] = [
    6.640638685223223, 49.44217524579125,
  ]
  return (
    <>
      <Seo title="Kontakt" />
      <ContactPageTemplate {...{ layout, contact, mapCenter }} />
    </>
  )
}

export default ContactPage
